/** @jsx jsx */
import { jsx } from 'theme-ui'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share'

const ShareButtons = ({ url, isSmall = false }) => {
  return (
    <div
      sx={{
        display: isSmall ? ['none', 'none', 'grid'] : 'grid',
        justifyContent: 'center',
        gridTemplateColumns: isSmall
          ? 'repeat(2, 100px)'
          : ['repeat(2, 100px)', 'repeat(4, 100px)'],
        gap: isSmall ? 3 : [3, 2],
      }}
    >
      <FacebookShareButton url={url}>
        <div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            borderRadius: '2px',
            background: '#3C579E',
          }}
        >
          <FacebookIcon
            size={32}
            bgStyle={{ fill: 'transparent' }}
            iconFillColor="#fff"
          />
        </div>
      </FacebookShareButton>
      <TwitterShareButton url={url}>
        <div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            borderRadius: '2px',
            background: '#54ACEE',
          }}
        >
          <TwitterIcon
            size={32}
            bgStyle={{ fill: 'transparent' }}
            iconFillColor="#fff"
          />
        </div>
      </TwitterShareButton>
      <WhatsappShareButton url={url}>
        <div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            borderRadius: '2px',
            background: '#25D366',
          }}
        >
          <WhatsappIcon
            size={32}
            bgStyle={{ fill: 'transparent' }}
            iconFillColor="#fff"
          />
        </div>
      </WhatsappShareButton>
      <TelegramShareButton url={url}>
        <div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            borderRadius: '2px',
            background: '#0088CC',
          }}
        >
          <TelegramIcon
            size={32}
            bgStyle={{ fill: 'transparent' }}
            iconFillColor="#fff"
          />
        </div>
      </TelegramShareButton>
    </div>
  )
}

export default ShareButtons
